import SvgIcon from '@mui/material/SvgIcon';

export default function Wallet(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M2.45898 5.33325C2.45898 3.6764 3.80213 2.33325 5.45898 2.33325H11.459C13.1158 2.33325 14.459 3.6764 14.459 5.33325V5.72189C16.3508 6.03928 17.7923 7.68459 17.7923 9.66659V13.6666C17.7923 15.8757 16.0015 17.6666 13.7923 17.6666H6.45898C4.24984 17.6666 2.45898 15.8757 2.45898 13.6666V6.66659V5.33325ZM12.459 5.33325V5.66659H4.45898V5.33325C4.45898 4.78097 4.9067 4.33325 5.45898 4.33325H11.459C12.0113 4.33325 12.459 4.78097 12.459 5.33325ZM4.45898 13.6666V7.66659H13.459H13.7923C14.8969 7.66659 15.7923 8.56202 15.7923 9.66659V13.6666C15.7923 14.7712 14.8969 15.6666 13.7923 15.6666H6.45898C5.35441 15.6666 4.45898 14.7712 4.45898 13.6666ZM11.7923 10.6666C11.24 10.6666 10.7923 11.1143 10.7923 11.6666C10.7923 12.2189 11.24 12.6666 11.7923 12.6666H13.459C14.0113 12.6666 14.459 12.2189 14.459 11.6666C14.459 11.1143 14.0113 10.6666 13.459 10.6666H11.7923Z"
      />
    </SvgIcon>
  );
}